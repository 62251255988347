import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const userInfoInLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : sessionStorage.getItem("userInfo")
  ? JSON.parse(sessionStorage.getItem("userInfo"))
  : {};

export const userRegisterLoginSlice = createSlice({
  name: "userRegisterLogin",
  initialState: { userInfo: userInfoInLocalStorage },
  reducers: {
    loginUser: (state, action) => {
      // { type: userRegisterLogin/loginUser, payload: { userInfos } }
      return (state = { userInfo: action.payload });
    },
    logoutUser: (state, action) => {
      // { type: userRegisterLogin/logoutUser, payload: {} }
      document.location.href = "/login";
      axios.get("/api/logout");
      localStorage.removeItem("userInfo");
      sessionStorage.removeItem("userInfo");
      return (state = { userInfo: {} });
    },
  },
});

export const getUserInfoRedux = (state) => state.userRegisterLogin;
