import { Spinner } from "react-bootstrap";

const LoadingSpinner = () => {
  return (
    <div className="loading-container text-center">
      <Spinner
        as="span"
        className="loading-spinner"
        animation="border"
        role="status"
        aria-hidden="true"
      />
    </div>
  );
};

export default LoadingSpinner;
