import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const cartItemsInLocalStorage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];
const initialCartSubtotal = cartItemsInLocalStorage
  ? cartItemsInLocalStorage.reduce(
      (price, item) => price + Number(item.price) * Number(item.quantity),
      0
    )
  : 0;
const initialCartWeight = cartItemsInLocalStorage
  ? cartItemsInLocalStorage.reduce(
      (weight, item) => weight + Number(item.weight) * Number(item.quantity),
      0
    )
  : 0;

export const addItemsToCart = createAsyncThunk(
  "cart/addItemsToCart",
  async (info) => {
    const { data } = await axios.get(`/api/products/get-one/${info.id}`);

    return {
      productId: data.product[0].id,
      name: data.product[0].name,
      price: Number(data.product[0].finalPrice),
      image: data.product[0].images[0] || null,
      stock: Number(data.product[0].stock_quantity),
      weight: Number(data.product[0].weight),
      quantity: Number(info.quantity),
      pricePerUnit: Number(data.product[0].price),
      promotionRate: Number(data.promotionInfos.promotionRate) || 0,
    };
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: cartItemsInLocalStorage,
    cartSubtotal: Number(initialCartSubtotal),
    cartWeight: Number(initialCartWeight),
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    removeFromCart: {
      reducer(state, action) {
        state.cartSubtotal -=
          Number(action.payload.price) * Number(action.payload.quantity);
        state.cartWeight -=
          Number(action.payload.weight) * Number(action.payload.quantity);
        state.cartItems = state.cartItems.filter(
          (x) => x.productId !== action.payload.productId
        );
        localStorage.setItem("cart", JSON.stringify(state.cartItems));
        return state;
      },
      prepare(productInfo) {
        return {
          payload: {
            productId: productInfo.productId,
            quantity: Number(productInfo.quantity),
            price: Number(productInfo.price),
            weight: Number(productInfo.weight),
          },
        };
      },
    },
    emptyTheCart: (state, action) => {
      state.cartItems = [];
      state.cartSubtotal = 0;
      state.cartWeight = 0;
      state.status = "idle";
      state.error = null;
      localStorage.removeItem("cart");
    },
  },
  extraReducers(builder) {
    builder
      .addCase(addItemsToCart.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addItemsToCart.fulfilled, (state, action) => {
        state.status = "succeeded";
        const productAddedToCart = action.payload;
        const productAlreadyInCart = state.cartItems.find(
          (productInCart) =>
            productInCart.productId === productAddedToCart.productId
        );

        if (productAlreadyInCart) {
          state.cartSubtotal = 0;
          state.cartWeight = 0;
          state.cartItems.map((productInCart, idx) => {
            if (productInCart.productId === productAlreadyInCart.productId) {
              state.cartItems[idx].quantity = productAddedToCart.quantity;
              const sumPrice =
                Number(productAddedToCart.quantity) * Number(productAddedToCart.price);
              state.cartSubtotal += sumPrice;
              const sumWeight =
                Number(productAddedToCart.quantity) * Number(productAddedToCart.weight);
              state.cartWeight += sumWeight;
              return productAddedToCart;
            } else {
              const sumPrice =
                Number(productInCart.quantity) * Number(productInCart.price);
              state.cartSubtotal += sumPrice;
              const sumWeight =
                Number(productAddedToCart.quantity) * Number(productAddedToCart.weight);
              state.cartWeight += sumWeight;
              return productInCart;
            }
          });
        } else {
          state.cartItems.push(productAddedToCart);
          const sumPrice = Number(productAddedToCart.quantity) * Number(productAddedToCart.price);
          state.cartSubtotal += sumPrice;
          const sumWeight =
            Number(productAddedToCart.quantity) * Number(productAddedToCart.weight);
          state.cartWeight += sumWeight;
        }

        localStorage.setItem("cart", JSON.stringify(state.cartItems));
        return state;
      })
      .addCase(addItemsToCart.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getCartItems = (state) => state.cart.cartItems;
export const getCartItemCount = (state) => state.cart.cartItems.length;
export const getCartSubtotal = (state) => state.cart.cartSubtotal;
export const getCartWeight = (state) => state.cart.cartWeight;
export const getCartStatus = (state) => state.cart.status;
export const getCartError = (state) => state.cart.error;
