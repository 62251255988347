import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

const ErrorBoundaryFallback = () => {
  return (
    <div className="error-fallback-div">
      <p>Quelque chose s'est mal passé...</p>
      <LinkContainer to="/">
        <Link className="return-to-link">Retour à l'accueil</Link>
      </LinkContainer>
    </div>
  );
};

export default ErrorBoundaryFallback;
