import CategoryCardComponent from "../components/homepage/CategoryCardComponent";
import HomepageCarouselComponent from "../components/homepage/HomepageCarouselComponent";
import HomepagePresentationComponent from "../components/homepage/HomepagePresentationComponent";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { getReduxCategory } from "../redux/slices/categorySlice";
import MetaComponent from "../components/MetaComponent";

const HomePage = () => {
  const { categories } = useSelector(getReduxCategory);
  // const isLoading = useSelector(getReduxCategory).isLoading;
  const error = useSelector(getReduxCategory).error;

  if (error) {
    console.error(error);
  }


  return (
    <>
      <MetaComponent title={"4YouMaquillage"} description={"Achetez vos maquillages, parfums et produits de beauté au meilleur prix sur For You Maquillage"} pageLocation={"/"} />
      <HomepageCarouselComponent />
      <HomepagePresentationComponent />
      <Container id="cards-categories-container">
        <h2 id="hp-cat-cards-title">Explorez notre catalogue</h2>
        <Row xs={1} sm={2} md={2} lg={3} xl={3} xxl={4} className="g-4 m-2">
          {categories.map((category, index) => (
            <Col key={index} className="cards-categories-col mx-auto">
              <CategoryCardComponent
                key={index}
                category={category}
                index={index}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
