import Carousel from "react-bootstrap/Carousel";
import { LinkContainer } from "react-router-bootstrap";

const HomepageCarouselComponent = () => {
  const cursorPointer = {
    cursor: "pointer",
  };

  return (
    <Carousel id="hp-carousel">
      <Carousel.Item>
        <img
          crossOrigin="anonymous"
          className="d-block w-100"
          style={{ height: "300px", objectFit: "cover", width: "100vw" }}
          src="/images/carousel/element5-digital-ceWgSMd8rvQ-unsplash-1440x628.webp"
          srcSet="/images/carousel/element5-digital-ceWgSMd8rvQ-unsplash-700x305.webp 700w, /images/carousel/element5-digital-ceWgSMd8rvQ-unsplash-1440x628.webp 1440w"
          sizes="100vw"
          alt="Illustration par Element5 Digital pour Unsplash"
          fetchpriority="high"
          loading="eager"
        />
        <Carousel.Caption className="hp-carousel-caption-container">
          <div className="hp-carousel-title-div">
            <LinkContainer to="/catalogue" style={cursorPointer} state={{ onlyPromoted: true }}>
              <h3 className="hp-carousel-title text-center">Promotions</h3>
            </LinkContainer>
          </div>
          <LinkContainer to="/catalogue" style={cursorPointer} state={{ onlyPromoted: true }}>
            <p className="hp-carousel-subtitle">
              Découvrez nos articles actuellement en promotion.
            </p>
          </LinkContainer>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          loading="lazy"
          crossOrigin="anonymous"
          style={{ height: "300px", objectFit: "cover", width: "100vw" }}
          src="/images/carousel/jakub-chlouba-syCYXA-UJlY-unsplash-1440x596.webp"
          srcSet="/images/carousel/jakub-chlouba-syCYXA-UJlY-unsplash-700x290.webp 700w, /images/carousel/jakub-chlouba-syCYXA-UJlY-unsplash-1440x596.webp 1440w"
          sizes="100vw"
          alt="Illustration par Jakub Chlouba pour Unsplash"
        />
        <Carousel.Caption className="hp-carousel-caption-container">
          <div className="hp-carousel-title-div">
            <LinkContainer to="/catalogue" style={cursorPointer} state={{ bestsellers: true }}>
              <h3 className="hp-carousel-title text-center">
                Meilleures ventes
              </h3>
            </LinkContainer>
          </div>
          <LinkContainer to="/catalogue" style={cursorPointer} state={{ bestsellers: true }}>
            <p className="hp-carousel-subtitle">
              Les articles les plus vendus de la boutique.
            </p>
          </LinkContainer>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          crossOrigin="anonymous"
          loading="lazy"
          style={{ height: "300px", objectFit: "cover", width: "100vw" }}
          src="/images/carousel/kevin-laminto-LL1vA5sUs6g-unsplash-1440x679.webp"
          srcSet="/images/carousel/kevin-laminto-LL1vA5sUs6g-unsplash-700x300.webp 700w, /images/carousel/kevin-laminto-LL1vA5sUs6g-unsplash-1440x679.webp 1440w"
          sizes="100vw"
          alt="Illustration par Kevin Laminto pour Unsplash"
        />

        <Carousel.Caption className="hp-carousel-caption-container">
          <div className="hp-carousel-title-div">
            <LinkContainer to="/catalogue" style={cursorPointer} state={{ newest: true }}>
              <h3 className="hp-carousel-title text-center">Nouveautés</h3>
            </LinkContainer>
          </div>
          <LinkContainer to="/catalogue" style={cursorPointer} state={{ newest: true }}>
            <p className="hp-carousel-subtitle">
              Jettez un oeil à nos nouveaux produits.
            </p>
          </LinkContainer>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default HomepageCarouselComponent;
