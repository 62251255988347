import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    return () => {};
  }, [pathname]);
}

export const ScrollToTopButton = () => {
  const [showScrollButton, setShowScrollButton] = useState("none");

  window.onscroll = function () {
    testScrollHeight();
  };

  function testScrollHeight() {
    let scrollTest =
      window.scrollY ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
    if (scrollTest > 1200) {
      setShowScrollButton("block");
    } else {
      setShowScrollButton("none");
    }
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <button
      onClick={goToTop}
      id="scrollToTopButton"
      style={{ display: showScrollButton }}
      title="scrollToTopBtn"
    >
      ⤒
    </button>
  );
};
