import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  categories: [],
  isLoading: false,
  error: null,
};

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async () => {
    const { data } = await axios.get("/api/categories");
    return data;
  }
);

export const createSubcategory = createAsyncThunk(
  "category/createSubcategory",
  async (newSubcategory) => {
    const { data } = await axios.post(
      "/api/categories/admin/newSubcategory",
      newSubcategory
    );
    if (data === "new subcategory created") {
      const categoriesData = await axios.get("/api/categories");
      return categoriesData.data;
    } else {
      return data;
    }
  }
);

export const deleteSubcategory = createAsyncThunk(
  "category/deleteSubcategory",
  async (subcategoryId) => {
    const { data } = await axios.delete("/api/categories/admin/" + subcategoryId);
    if (data === "Subcategory deleted") {
      const categoriesData = await axios.get("/api/categories");
      return categoriesData.data;
    } else {
      return data;
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchCategories
    builder.addCase(fetchCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // createSubcategory
    builder.addCase(createSubcategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSubcategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    });
    builder.addCase(createSubcategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    // deleteSubcategory
    builder.addCase(deleteSubcategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSubcategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload;
    });
    builder.addCase(deleteSubcategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const getReduxCategory = (state) => state.category;
// export default categorySlice.reducer
