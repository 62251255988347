import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
  const acceptCookies = () => {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + 30);
    document.cookie = "cookieConsent=userAccept; expires = " + todayDate  + ";";
    document.getElementById("cookies-banner").style = "display: none;"
  }

  const checkCookieAccepted = () => {
    let input = document.cookie.split("=");
    (input[0] === "cookieConsent") ? document.getElementById("cookies-banner").style = "display: none;" : document.getElementById("cookies-banner").style = "display: block;"
  }

  useEffect(() => {
    checkCookieAccepted();
  }, [])

  return (
    <div id="cookies-banner" style={{"display": "block"}}>
      Par respect pour vos informations personnelles, ce site n'utilise que des cookies strictement nécessaires à son fonctionnement. <Link to="/cookie" arial-label="Allez à la page cookie">Cliquez ici</Link> pour en savoir plus.
      <button id="cookies-banner-btn" onClick={acceptCookies}>Compris</button>
    </div>
  )
} //className="my-green-button"

export default CookieBanner;
