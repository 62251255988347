import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { getUserInfoRedux } from "../redux/slices/userRegisterLoginSlice";
import { useSelector } from "react-redux";

const ShopInPauseComponent = ({ isVisible }) => {
  const [isComponentVisible, setComponentVisibility] = useState(isVisible);
  const [isComponentVisibleOpacity, setComponentVisibilityOpacity] = useState(isVisible);
  const location = useLocation();
  const { userInfo } = useSelector(getUserInfoRedux);

  useEffect(() => {
    if (userInfo.isAdmin === 1) {
      setComponentVisibility(false)
    }  else {
      setComponentVisibility(true);
      setComponentVisibilityOpacity(true);
    }
  }, [location, userInfo]);

  const closeTab = () => {
    setComponentVisibilityOpacity(false);
    setTimeout(() => {
      setComponentVisibility(false);
    }, 520);
  }


  return isComponentVisible ? (
    <div id="pause-banner-wrapper" className={`pause-banner-fade ${isComponentVisibleOpacity ? 'visible' : 'hidden'}`}>
      <h2>La boutique est actuellement fermée</h2>
      <h4>Aucune commande n'est acceptée pour le moment</h4>
      <h4>Nous serons de retour prochainement !</h4>
      <button type="button" id="pause-banner-btn" onClick={closeTab}>╳</button>
    </div>
  ) : null;

}

export default ShopInPauseComponent;
