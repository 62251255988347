import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";

import { cartSlice } from "./slices/cartSlice";
import { userRegisterLoginSlice } from "./slices/userRegisterLoginSlice";
import { categorySlice } from "./slices/categorySlice";

export const { removeFromCart, emptyTheCart } = cartSlice.actions;
export const { loginUser, logoutUser } = userRegisterLoginSlice.actions;

export const store = configureStore(
  {
    reducer: {
      cart: cartSlice.reducer,
      userRegisterLogin: userRegisterLoginSlice.reducer,
      category: categorySlice.reducer,
    },
  },
  composeWithDevTools()
);
