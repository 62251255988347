import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

// Components
import ProtectedRoutesComponent from "./components/ProtectedRoutesComponents";
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import LoadingSpinner from "./components/LoadingSpinner";
import CookieBanner from "./components/CookieBanner";

// Utils
import { ScrollToTop } from "./utils/ScrollToTop";
import ErrorBoundaryFallback from "./utils/ErrorBoundaryFallback";

// Public routes
import HomePage from "./pages/HomePage";
import ShopInPauseComponent from "./components/ShopInPauseComponent";
const LoginPage = lazy(() => import("./pages/LoginPage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage"));
const CartPage = lazy(() => import("./pages/CartPage"));
const ProductsListPage = lazy(() => import("./pages/ProductsListPage"));
const ProductDetailsPage = lazy(() => import("./pages/ProductDetailsPage"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const AboutPage = lazy(() => import("./pages/AboutPage"));
const LegalPage = lazy(() => import("./pages/LegalPage"));
const CGVPage = lazy(() => import("./pages/CGVPage"));

// User routes
const UserCartDetails = lazy(() => import("./pages/user/UserCartDetails"));
const UserOrderDetailsPage = lazy(() =>
  import("./pages/user/UserOrderDetailsPage")
);
const UserOrdersPage = lazy(() => import("./pages/user/UserOrdersPage"));
const UserProfilePage = lazy(() => import("./pages/user/UserProfilePage"));

// Admin routes
const AdminAnalyticsPage = lazy(() =>
  import("./pages/admin/AdminAnalyticsPage")
);
const AdminCreateProductPage = lazy(() =>
  import("./pages/admin/AdminCreateProductPage")
);
const AdminCreateVariantProductPage = lazy(() =>
  import("./pages/admin/AdminCreateVariantProductPage")
);
const AdminEditProductPage = lazy(() =>
  import("./pages/admin/AdminEditProductPage")
);
const AdminEditUserPage = lazy(() => import("./pages/admin/AdminEditUserPage"));
const AdminOrderDetailsPage = lazy(() =>
  import("./pages/admin/AdminOrderDetailsPage")
);
const AdminOrdersPage = lazy(() => import("./pages/admin/AdminOrdersPage"));
const AdminProductsPage = lazy(() => import("./pages/admin/AdminProductsPage"));
const AdminUsersPage = lazy(() => import("./pages/admin/AdminUsersPage"));
const AdminPromotionsPage = lazy(() =>
  import("./pages/admin/AdminPromotionsPage")
);
const AdminCreatePromotionPage = lazy(() =>
  import("./pages/admin/AdminCreatePromotionPage")
);
const AdminEditPromotionPage = lazy(() =>
  import("./pages/admin/AdminEditPromotionPage")
);
const AdminCategoriesPage = lazy(() =>
  import("./pages/admin/AdminCategoriesPage")
);

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderComponent />
      {/* Todo : isVisible={false} */}
      <ShopInPauseComponent isVisible={true} />
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<HomePage />} />
        <Route
          path="/login"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <LoginPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/register"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <RegisterPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/forgotPassword"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ForgotPasswordPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/resetPassword/:resetToken"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ResetPasswordPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/panier"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <CartPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/catalogue"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ProductsListPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/catalogue/:pageNumParam"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ProductsListPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/catalogue/recherche/:searchQuery"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ProductsListPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/catalogue/recherche/:searchQuery/:pageNumParam"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ProductsListPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/produit/:id"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ProductDetailsPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/contact"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ContactPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/about"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <AboutPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/legal"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <LegalPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="/cgv"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <CGVPage />
              </Suspense>
            </ErrorBoundary>
          }
        />
        <Route
          path="*"
          element={
            <ErrorBoundary fallback={ErrorBoundaryFallback}>
              <Suspense fallback={<LoadingSpinner />}>
                <ErrorPage />
              </Suspense>
            </ErrorBoundary>
          }
        />

        {/* User protected routes */}
        <Route element={<ProtectedRoutesComponent admin={false} />}>
          <Route
            path="/profile"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <UserProfilePage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/profile/details-panier"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <UserCartDetails />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/profile/mes-commandes"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <UserOrdersPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/profile/details-commande/:reference"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <UserOrderDetailsPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>

        {/* Admin protected routes */}
        <Route element={<ProtectedRoutesComponent admin={true} />}>
          <Route
            path="admin/clients"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminUsersPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/modifier-client/:id"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminEditUserPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/produits"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminProductsPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/ajouter-produit"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminCreateProductPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/ajouter-variant/:id"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminCreateVariantProductPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/modifier-produit/:id"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminEditProductPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/commandes"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminOrdersPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/details-commande/:reference"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminOrderDetailsPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/categories"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminCategoriesPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/promotions"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminPromotionsPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/ajouter-promotion"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminCreatePromotionPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/modifier-promotion/:id"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminEditPromotionPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="admin/analytiques"
            element={
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <Suspense fallback={<LoadingSpinner />}>
                  <AdminAnalyticsPage />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Route>
      </Routes>
      <CookieBanner />
      <FooterComponent />
    </BrowserRouter>
  );
}

export default App;
