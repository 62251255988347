import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

const FooterComponent = () => {
  return (
    <footer>
      <div className="footer-container">
        <Row>
          <h6 className="footer-title">
            4YouMaquillage &copy; Tous droits réservés
          </h6>
        </Row>
        <Row>
          <Col className="footer-col">
            <Row className="footer-row">
              <div>
                <LinkContainer to="/about">
                  <Link className="footer-link">A propos</Link>
                </LinkContainer>
              </div>
            </Row>
            <Row className="footer-row">
              <div>
                <LinkContainer to="/contact">
                  <Link className="footer-link">Nous contacter</Link>
                </LinkContainer>
              </div>
            </Row>
          </Col>
          <Col className="footer-col">
            <Row className="footer-row">
              <div>
                <LinkContainer to="/legal">
                  <Link className="footer-link">Mentions légales - Politique de confidentialité</Link>
                </LinkContainer>
              </div>
            </Row>
            <Row className="footer-row">
              <div>
                <LinkContainer to="/cgv">
                  <Link className="footer-link">
                    Conditions générales de vente
                  </Link>
                </LinkContainer>
              </div>
            </Row>
          </Col>
          <Col className="footer-col">
            <Row className="footer-row">
              <div>
                <LinkContainer to="/" aria-label="Lien vers notre page Facebook">
                  <Link className="footer-link">
                    <i
                      style={{ fontSize: "24px" }}
                      className="bi bi-facebook"
                    ></i>
                  </Link>
                </LinkContainer>
              </div>
            </Row>
            <Row className="footer-row">
              <div>
                <LinkContainer to="/" aria-label="Lien vers notre page Instagram">
                  <Link className="footer-link">
                    <i
                      style={{ fontSize: "24px" }}
                      className="bi bi-instagram"
                    ></i>
                  </Link>
                </LinkContainer>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default FooterComponent;
