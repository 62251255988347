
const HomepagePresentationComponent = () => {
  return (
    <div className="hp-presentation">
      <hr />
      <h1 className="hp-pres-title">Bienvenue chez ForYouMaquillage</h1>
      <section>
        <h3>Nos engagements</h3>
        <p>Nous croyons en la beauté de la diversité et nous nous engageons à offrir <strong>des produits adaptés à chaque peau</strong>, qu'elle soit claire, foncée, sèche, grasse ou mixte.</p>
        <p>Notre large gamme de produits cosmétiques est conçue pour répondre aux besoins spécifiques de chaque type de peau. Que vous recherchiez des fonds de teint, des correcteurs, des poudres, des rouges à lèvres ou encore des produits pour les soins de la peau, nous avons ce qu'il vous faut pour sublimer votre beauté naturelle.</p>
        <p>Nous sommes impatients de vous accompagner dans votre parcours beauté et de vous aider à vous sentir belle, quelle que soit votre couleur de peau.</p>
      </section>
      <hr />
    </div>
  )
}

export default HomepagePresentationComponent
