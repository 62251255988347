import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaComponent = ({ pageLocation, title = "4YouMaquillage", description = "Achetez vos maquillages, parfums et produits de beauté au meilleur prix sur For You Maquillage" }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} data-rh="true" />
        <meta name="description" content={description} data-rh="true" />
        {pageLocation && (
          <link rel="canonical" href={pageLocation} />
        )}
      </Helmet>
    </HelmetProvider>
  )
};

export default MetaComponent;
