import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const CategoryCardComponent = ({category, index}) => {
  const images = [
    "/images/categories/yeux-engin-akyurt-EeVOgK2x0E4-unsplash-342x342.webp",
    "/images/categories/levres-peter-kalonji-5eqZUR08qY8-unsplash-342x342.webp",
    "/images/categories/fond-teint-raphael-lovaski-DEuob2v77wI-unsplash-342x342.webp",
    "/images/categories/nail-element5-digital-Xf7o2W7qgP0-unsplash-342x342.webp",
    "/images/categories/parfum2-jc-media-zvqq7CG8BwY-unsplash-342x342.webp",
    "/images/categories/soin-kimia-zarifi-x4J_92kJBoY-unsplash-342x342.webp",
    "/images/categories/pinceaux-edz-norton-PEttXYw9hi8-unsplash-342x342.webp",
    "/images/categories/coffret-mia-golic-6JtuGvLzh20-unsplash-342x342.webp",
  ];
  const alt = [
    "Illustration d'un oeil par Engin Akyurt pour Unsplash",
    "Illustration de lèvres par Peter Kalonji pour Unsplash",
    "Illustration de fonds de teints par Raphael Lovaski pour Unsplash",
    "Illustration de vernis à ongles par Element5 Digital pour Unsplash",
    "Illustration d'un parfum par JC Media pour Unsplash",
    "Illustration de soin du visage par Kimia Zarifi pour Unsplash",
    "Illustration de pinceaux à maquillage par Edz Norton pour Unsplash",
    "Illustration de coffrets cadeaux par Mia Golic pour Unsplash",
  ]

  function getSubcategoriesId(subcategories) {
    const subcategoriesId = [];
    for (let subcategory of subcategories) {
      subcategoriesId.push(subcategory.id)
    }
    return subcategoriesId;
  }

  return (
    <Link className="product-cards-link" to="/catalogue" state={{subcategoriesId: getSubcategoriesId(category.subcategories), categoryName: category.name}}>
      <Card className="hp-cat-cards">
        <Card.Img loading="lazy" crossOrigin="anonymous" id="hp-cat-cards-img" src={images[index]} alt={alt[index]} />
        <Card.Body id="hp-cat-cards-body">
          <Card.Title id="hp-cat-cards-subtitle">{category.name}</Card.Title>
          <Card.Text id="hp-cat-cards-text">
            {category.description}
          </Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
}

export default CategoryCardComponent
