import { Link, useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/store";

// React-Bootstrap framework
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

import { getCartItemCount } from "../redux/slices/cartSlice";
import { getUserInfoRedux } from "../redux/slices/userRegisterLoginSlice";
import {
  fetchCategories,
  getReduxCategory,
} from "../redux/slices/categorySlice";
import { useEffect, useState } from "react";

const HeaderComponent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [fullUsername, setFullUsername] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(getUserInfoRedux);
  const numOfItemsInCart = useSelector(getCartItemCount);
  const { categories } = useSelector(getReduxCategory);
  const isLoading = useSelector(getReduxCategory).isLoading;
  const error = useSelector(getReduxCategory).error;

  if (error) {
    console.error(error);
  }

  /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
  var prevScrollPos = window.scrollY;
  window.addEventListener('scroll',() => {
    var currentScrollPos = window.scrollY;
    let diff = prevScrollPos - currentScrollPos;
    if (document.getElementById("categories-navbar") && (diff > 15 || currentScrollPos < 100)) {
      document.getElementById("categories-navbar").style = "transform:scaleY(1);";
      document.getElementById("navbar-1").style = "border-bottom: none;";
    } else if (document.getElementById("categories-navbar") && diff < -15 && currentScrollPos > 100) {
      document.getElementById("categories-navbar").style = "transform:scaleY(0);";
      document.getElementById("navbar-1").style = "border-bottom: 1px solid #e9ecef;";
    }
    prevScrollPos = currentScrollPos;
  })

  const handleSubcategoryLink = (e) => {
    e.target.offsetParent.style = "visibility: hidden; opacity: 0;";
  }

  const submitHandler = (e) => {
    if (e.keyCode && e.keyCode !== 13) return;
    e.preventDefault();
    e.stopPropagation();
    if (searchQuery.trim()) {
      navigate(`/catalogue/recherche/${searchQuery}`);
    } else {
      navigate("/catalogue");
    }
  };

  function getSubcategoriesId(subcategories) {
    const subcategoriesId = [];
    for (let subcategory of subcategories) {
      subcategoriesId.push(subcategory.id)
    }
    return subcategoriesId;
  }

  function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
    };
  }

  window.addEventListener('resize', () => {
    let ulNodeList = document.querySelectorAll('li > ul');
    let windowSize = window.innerWidth;
    for (let ulNode of ulNodeList) {
      const ulPosition = getOffset(ulNode).left;
      if ((windowSize - ulPosition) < 250) {
        ulNode.style = "right: 2px;"
      } else {
        ulNode.style = "right: unset;"
      }
    }
  });

  useEffect(() => {
    let firstName = "";
    let lastName = "";

    userInfo.name?.replaceAll(/[_ ]/gm, '-').split('-').map(x => firstName += x[0] + '.');
    userInfo.lastName?.length > 12 ? lastName = userInfo.lastName.slice(0, 10) + "..." : lastName = userInfo.lastName;

    setFullUsername(firstName + " " + lastName)
  }, [userInfo])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [categories])

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  return (
    <div className="header" id="navbar-header">
      <Navbar collapseOnSelect expand="md" className="my-navbar navbar1" id="navbar-1">
        <Container fluid>
          <Col>
            <Navbar.Brand href="/">
              <img
                src="../../images/logo/logo_svg.svg"
                id="logo-navbar"
                alt="ForYouMaquillage logo"
                style={{height: "46px", width: '61px'}}
              />
            </Navbar.Brand>
          </Col>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <InputGroup id="navbar-searchbar-input-group">
                <Button id="navbar-button" onClick={submitHandler} aria-label="Lancer la recherche">
                  <i className="bi bi-search"></i>
                </Button>
                <Form.Control
                  type="search"
                  id="navbar-searchbar"
                  placeholder="Rechercher un produit..."
                  className="ms-auto"
                  aria-label="Entrez votre recherche"
                  onKeyUp={submitHandler}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </InputGroup>
            </Nav>
            <Nav>
              {userInfo.isAdmin ? (
                <NavDropdown
                  title="ADMIN"
                  className="collapsible-nav-dropdown"
                  id="collapsible-nav-dropdown-admin"
                  align="end"
                >
                  <NavDropdown.Item
                    id="navbar-admin-item-1"
                    eventKey="/admin/commandes"
                    as={Link}
                    to="/admin/commandes"
                    className="navbar-client-and-admin-items"
                  >
                    Commandes
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="navbar-admin-item-2"
                    eventKey="/admin/produits"
                    as={Link}
                    to="/admin/produits"
                    className="navbar-client-and-admin-items"
                  >
                    Produits
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="navbar-admin-item-3"
                    eventKey="/admin/promotions"
                    as={Link}
                    to="/admin/promotions"
                    className="navbar-client-and-admin-items"
                  >
                    Promotions
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="navbar-admin-item-4"
                    eventKey="/admin/clients"
                    as={Link}
                    to="/admin/clients"
                    className="navbar-client-and-admin-items"
                  >
                    Clients
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="navbar-admin-item-5"
                    eventKey="/admin/categories"
                    as={Link}
                    to="/admin/categories"
                    className="navbar-client-and-admin-items"
                  >
                    Catégories
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="navbar-admin-item-6"
                    eventKey="/admin/analytiques"
                    as={Link}
                    to="/admin/analytiques"
                    className="navbar-client-and-admin-items"
                  >
                    Analytique
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    id="navbar-admin-item-7"
                    eventKey="/profile"
                    as={Link}
                    to="/profile"
                    className="navbar-client-and-admin-items"
                  >
                    Mon profile
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="navbar-admin-item-8"
                    className="navbar-client-and-admin-items"
                    onClick={() => dispatch(logoutUser())}
                  >
                    Se déconnecter
                  </NavDropdown.Item>
                </NavDropdown>
              ) : userInfo.name && !userInfo.isAdmin ? (
                <NavDropdown
                  title={fullUsername}
                  className="collapsible-nav-dropdown"
                  id="collapsible-nav-dropdown-client"
                  align="end"
                >
                  <NavDropdown.Item
                    id="navbar-client-item-1"
                    eventKey="/profile"
                    as={Link}
                    to="/profile"
                    className="navbar-client-and-admin-items"
                  >
                    Mon profile
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="navbar-client-item-2"
                    eventKey="/profile/mes-commandes"
                    as={Link}
                    to="/profile/mes-commandes"
                    className="navbar-client-and-admin-items"
                  >
                    Mes commandes
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    id="navbar-client-item-3"
                    className="navbar-client-and-admin-items"
                    onClick={() => dispatch(logoutUser())}
                  >
                    Se déconnecter
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <LinkContainer to="/login">
                    <Nav.Link>Se connecter</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/register">
                    <Nav.Link>S'inscrire</Nav.Link>
                  </LinkContainer>
                </>
              )}
              <LinkContainer to="/panier" id="navbar-cart-container" aria-label="Aller au panier">
                <Nav.Link>
                  <span
                    className="bi bi-cart-dash"
                    id="navbar-cart-icon"
                  ></span>
                  {numOfItemsInCart > 0 && (
                    <span className="navbar-cart-item-quantity">
                      {numOfItemsInCart < 10 ? numOfItemsInCart : "9+"}
                    </span>
                  )}
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isLoading ? (
        <></>
      ) : (
        <nav id="categories-navbar">
          {categories && categories.map((category, index) => (
            <ul key={`category-ul-${index}`}>
              <li key={`category-li-${index}`}>
                <Link
                  to="/catalogue"
                  state={{subcategoriesId: getSubcategoriesId(category.subcategories), categoryName: category.name}}
                  className="categories-navbar-link"
                >{category.name}</Link>
                <ul key={`subcategory-ul-${index}`} id={`subcategory-ul-${index}`} style={(categories.length === index + 1) ? {"right": "2px"} : {"right": "unset"}}>
                  {category.subcategories.map((subcategory, idx) => (
                    <LinkContainer
                      key={`subcategory-link-${idx}`}
                      to="/catalogue"
                      state={{subcategoriesId: [subcategory.id], subcategoryName: subcategory.name}}
                      className="categories-navbar-link"
                      onClick={handleSubcategoryLink}
                    >
                      <li key={`subcategory-li-${idx}`}>{subcategory.name}</li>
                    </LinkContainer>
                  ))}
                </ul>
              </li>
            </ul>
          ))}
        </nav>
      )}
    </div>
  );
};

export default HeaderComponent;
