import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import reportWebVitals from "./reportWebVitals";
// import(/* webpackPreload: true */ './fonts/cormorant-medium.woff');
// import(/* webpackPreload: true */ './fonts/playfairdisplay-bold.woff2');
// import(/* webpackPreload: true */ './fonts/playfairdisplay-regular.woff2');
// import(/* webpackPreload: true */ './fonts/raleway-medium.woff2');


const root = ReactDOM.createRoot(document.getElementById("root"));
const paypalProviderOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: 'EUR',
};

root.render(
  <>
    <Provider store={store}>
      <PayPalScriptProvider options={paypalProviderOptions} deferLoading={true}>
          <App />
      </PayPalScriptProvider>
    </Provider>
  </>
);
// React.StrictMode
reportWebVitals();
